<template>
  <div>
    <v-card class="card-custom-shadow mb-5">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="3" lg="1">
            <v-autocomplete
              v-model="transferType"
              :items="typeOptions"
              @change="onTypeChange"
              class="c-input-small"
              :label="$t('labels.transfer_type')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-autocomplete
              v-model="fromId"
              :items="fromOptions"
              @change="onFromChange"
              class="c-input-small"
              :label="$t('labels.from')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-autocomplete
              v-model="order.to_id"
              :items="toOptions"
              @change="onToChange"
              class="c-input-small"
              :label="$t('labels.to')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-text-field
              v-model="order.customer_order_id"
              class="c-input-small"
              :label="$t('labels.customer_order_id')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-autocomplete
              v-model="order.id_delivery_company"
              :items="deliveryCompanyOptions"
              @change="onDeliveryCompanyChange"
              class="c-input-small"
              :label="$t('labels.delivery_company')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-text-field
              v-model="order.delivery_order_id"
              :disabled="[-1].includes(order.id_delivery_company)"
              class="c-input-small"
              :label="$t('labels.delivery_order_id')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="order.note"
              class="c-input-small"
              :label="$t('labels.note')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-btn
              color="success"
              block
              :disabled="isDisabledBtnSubmit"
              @click="createTransfer"
              style="height: 40px"
            >
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-btn
              color="primary"
              @click="showQuantityConfig"
              block
              style="height: 40px"
            >
              {{ $t("labels.config") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-autocomplete
              v-model.number="order.break_type"
              :items="breakTypeOptions"
              @change="onBreakTypeChange"
              class="c-input-small input-error"
              :label="$t('labels.order_break')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="1">
            <v-text-field
              v-model.number="order.break_value"
              class="c-input-small input-error"
              :label="breakValueLabel"
              :disabled="!breakValueLabel"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" lg="10" class="error--text font-italic">
            <div class="align-center d-flex h-100">
              <b class="mr-1">{{ $t("labels.noted") }}:</b>
              {{ $t("labels.transfer_location_noted") }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="card-custom-shadow">
          <v-card-text>
            <v-simple-table class="table-padding-2">
              <template v-slot:default>
                <thead class="v-data-table-header">
                  <tr>
                    <th>
                      <InputFilter
                        :label="$t('labels.barcode')"
                        :placeholder="$t('labels.barcode')"
                        name="customer_goods_barcode"
                        sort-name="customer_goods_barcode"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <InputFilter
                        :label="$t('labels.name')"
                        :placeholder="$t('labels.name')"
                        name="name"
                        sort-name="name"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <InputFilter
                        :label="$t('labels.goods_description')"
                        :placeholder="$t('labels.goods_description')"
                        name="description"
                        sort-name="description"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <InputFilter
                        :label="$t('labels.size')"
                        :placeholder="$t('labels.size')"
                        name="size"
                        sort-name="size"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th class="text-center">
                      <InputFilterFromTo
                        :label="$t('labels.available_quantity_1')"
                        :placeholder="$t('labels.available_quantity_1')"
                        name="available"
                        sort-name="available"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <SelectFilter
                        :label="$t('labels.image')"
                        :placeholder="$t('labels.image')"
                        name="image"
                        sort-name="url_images"
                        :options="imageOptions"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th class="text-center">
                      <InputFilterFromTo
                        :label="$t('labels.price_0')"
                        :placeholder="$t('labels.price_0')"
                        name="price"
                        sort-name="price"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody v-if="items.length > 0">
                  <tr
                    v-for="(item, index) in items"
                    :key="`g_${item.id}_${index}`"
                    class="text-center"
                  >
                    <td>{{ item.customer_goods_barcode }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ formatNumber(item.available) }}</td>
                    <td>
                      <ImageViewer
                        v-if="item.url_images"
                        :url="item.url_images"
                      />
                    </td>
                    <td>
                      <template v-if="!selectedIds.includes(item.id)">
                        <v-btn
                          x-small
                          color="primary"
                          v-if="item.price !== null"
                          @click="selectItem(item, 'price')"
                        >
                          {{ formatNumber(item.price) }} đ
                        </v-btn>
                        <span class="font-italic error--text" v-else>
                          {{ $t("labels.not_yet_price") }}
                        </span>
                      </template>
                      <template v-else
                        >{{ formatNumber(item.price) }} đ</template
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7" class="text-center vertical-align-middle">
                      {{ $t("labels.not_yet_select_from_to") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="pt-3" v-if="items.length > 0">
              <v-pagination
                v-model="page"
                :length="totalPage"
                :total-visible="6"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="card-custom-shadow">
          <v-card-text>
            <v-simple-table class="table-padding-2">
              <template v-slot:default>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.barcode") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.name") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.goods_description") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.size") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.available_quantity_1") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.quantity") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.price_0") }}
                    </th>
                    <th
                      class="text-center vertical-align-middle"
                      style="width: 168px"
                    >
                      <a
                        :href="templateLink"
                        target="_blank"
                        class="mr-1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-small primary"
                      >
                        {{ $t("labels.sample_file") }}
                      </a>
                      <v-btn
                        :disabled="!fromId"
                        color="success"
                        x-small
                        class="mr-1"
                        @click="$refs.inputUploadFile.click()"
                      >
                        {{ $t("labels.upload") }}
                      </v-btn>
                      <input
                        type="file"
                        ref="inputUploadFile"
                        accept=".xlsx"
                        @change="onInputFileChange"
                        class="d-none"
                      />

                      <v-btn
                        v-if="selectItems.length > 0"
                        color="warning"
                        x-small
                        style="min-width: 20px; height: 20px; padding: 0"
                        @click="resetSelected"
                      >
                        <v-icon style="font-size: 14px">mdi-restore</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="selectItems.length > 0">
                  <tr
                    v-for="item in selectItems"
                    :key="`s${item.id}`"
                    class="text-center"
                  >
                    <td style="width: 105px">
                      {{ item.customer_goods_barcode }}
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ formatNumber(item.available) }}</td>
                    <td style="width: 60px">
                      <v-text-field
                        class="c-input-number-xs"
                        v-model.number="item.quantity"
                        type="number"
                        @keyup="quantityChange"
                        dense
                        single-line
                        outlined
                        hide-details
                      ></v-text-field>
                    </td>
                    <td>{{ formatNumber(item.price) }}</td>
                    <td>
                      <v-btn
                        color="error"
                        x-small
                        style="min-width: 20px; height: 20px; padding: 0"
                        @click="removeSelected(item)"
                      >
                        <v-icon style="font-size: 14px">mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center vertical-align-middle">
                      {{ $t("labels.not_yet_select_goods") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import {
  IMAGE_OPTIONS,
  TRANSFER_BREAK_TYPES,
  TRANSFER_TYPES,
} from "@/libs/const";
import { read, utils } from "xlsx";

export default {
  name: "TransferByLocation",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  props: {
    fType: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    isLoading: false,
    transferType: 1,
    fromToOptions: [],
    deliveryCompanyOptions: [],
    items: [],
    selectItems: [],
    imageOptions: [...IMAGE_OPTIONS],
    breakTypeOptions: [...TRANSFER_BREAK_TYPES],
    fromType: null,
    fromId: null,
    order: {
      to_type: null,
      to_id: null,
      id_delivery_company: null,
      delivery_order_id: null,
      note: null,
      break_type: null,
      break_value: "",
    },
    page: 1,
    totalPage: 1,
    filters: {},
    typeOptions: [...TRANSFER_TYPES],
  }),
  computed: {
    breakValueLabel() {
      switch (this.order.break_type) {
        case 2:
          return "Sản phẩm";
        case 3:
          return "Kg";
        default:
          return "";
      }
    },
    templateLink() {
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_dieu_chuyen.xlsx`;
    },
    isDisabledBtnSubmit() {
      if (
        !this.selectItems ||
        this.selectItems.length === 0 ||
        !this.fromId ||
        !this.order.to_id ||
        !this.order.break_type ||
        ([2, 3].includes(this.order.break_type) && !this.order.break_value)
      ) {
        return true;
      }
      return false;
    },
    selectedIds() {
      return [...this.selectItems].map((s) => s.id);
    },
    goodsItems() {
      return [...this.selectItems].map((item) => ({
        barcode: item.customer_goods_barcode,
        quantity: item.quantity,
        id: item.id,
      }));
    },
    orderConverted() {
      return {
        ...this.order,
        tvc: this.order.id_delivery_company !== -1 ? 1 : 0,
        tt: 1,
        goods_items: [...this.goodsItems],
      };
    },
    fromOptions() {
      return [...this.fromToOptions].filter((i) => i.type == this.fType);
    },
    toOptions() {
      if (!this.fromId) {
        return [];
      }
      return [...this.fromToOptions].filter(
        (i) =>
          i.type !== this.fromType ||
          (i.value !== this.fromId && i.type === this.fromType)
      ); //filter(i => i.type === 'warehouse')
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    const { warehouses, poss } = window.me;
    const warehouseOptions = [...warehouses].map((w) => ({
      value: w.id,
      text: `${this.$t("labels.warehouse_1")}: ${w.code_name || ""}`,
      type: "warehouse",
    }));
    const posOptions = [...poss].map((p) => ({
      value: p.id,
      text: `${this.$t("labels.pos")}: ${p.code || ""} - ${p.name || ""}`,
      type: "pos",
    }));
    this.fromToOptions = [...warehouseOptions, ...posOptions];
    this.getDeliveryCompany();
  },
  methods: {
    onFromChange() {
      this.items = [];
      this.selectItems = [];
      const fromItem = [...this.fromToOptions].find(
        (i) => i.value === this.fromId
      );
      if (!fromItem) {
        return false;
      }
      this.fromType = fromItem.type;
      this.getList();
    },
    onToChange() {
      const toItem = [...this.fromToOptions].find(
        (i) => i.value === this.order.to_id
      );
      this.order = { ...this.order, to_type: toItem.type };
    },
    onDeliveryCompanyChange() {
      if (this.order.id_delivery_company === -1) {
        this.order = { ...this.order, delivery_order_id: null };
      }
    },
    onBreakTypeChange() {
      this.order = { ...this.order, break_value: "" };
    },
    onTypeChange() {
      this.$emit("onTypeChange", this.transferType);
    },
    showQuantityConfig() {
      this.$emit("showQuantityConfig", true);
    },
    async getDeliveryCompany() {
      const { data } = await httpClient.post("/get-delivery-company");
      const options = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
      options.unshift({
        value: -1,
        text: "SSC",
      });
      this.deliveryCompanyOptions = [...options];
    },
    getList: debounce(function () {
      httpClient
        .post("/transfer-goods-list", {
          ...this.filters,
          from_type: this.fromType,
          from_id: this.fromId,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    quantityChange: debounce(function () {
      this.selectItems = [...this.selectItems];
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    selectItem(item) {
      item.quantity = 1;
      this.selectItems.push(item);
    },
    removeSelected(item) {
      this.selectItems = [...this.selectItems].filter((s) => s.id !== item.id);
    },
    resetSelected() {
      this.selectItems = [];
    },
    async createTransfer() {
      if (this.isDisabledBtnSubmit) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const items = [{ ...this.orderConverted }];
        let fd = new FormData();
        fd.append("orders", JSON.stringify(items));
        fd.append("from_type", this.fromType);
        fd.append("from_id", this.fromId);
        await httpClient.post("/transfer-create", fd);
        this.selectItems = [];
        this.order = { ...this.order, delivery_order_id: null, note: null };
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates = (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.validates) || {
          errRows: [],
          errTexts: [],
        };
        if (
          validates &&
          validates.errGroups &&
          validates.errGroups[0] &&
          validates.errGroups[0].errors
        ) {
          const errors = validates.errGroups[0].errors || [];
          this.$vToastify.error(errors.join("; "));
        } else {
          this.$vToastify.error(errMsg);
        }
      }
    },
    async onInputFileChange(e) {
      const mappingFields = {
        Barcode: "customer_goods_barcode",
        "Số lượngQuantity": "quantity",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            const mapkey = i.replace(/\r?\n|\r/g, "");
            item[mappingFields[mapkey]] = `${v[i]}`.trim();
          });
          return item;
        });
        await this.syncGoodsData(items);
      };
      await reader.readAsArrayBuffer(f);
    },
    async syncGoodsData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
      }
      if (items.length > 1000) {
        this.$vToastify.error(this.$t("messages.create_receipt_max_1000_rows"));
      }
      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find(
          (n) => n === `${i.customer_goods_barcode}`
        );
        if (!nd) {
          noDuplicateBarcodes.push(`$${i.customer_goods_barcode}`);
        } else {
          duplicateBarcodes.push(`${i.customer_goods_barcode}`);
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      const barcodes = [...items].map((i) => i.customer_goods_barcode);
      const { data } = await httpClient.post(
        "/get-goods-transfer-by-barcodes",
        { barcodes, from_type: this.fromType, from_id: this.fromId }
      );
      this.selectItems = [...items].map((i) => {
        const item = [...data].find(
          (d) => d.customer_goods_barcode == i.customer_goods_barcode
        );
        item.quantity = i.quantity;
        return item;
      });
    },
  },
};
</script>

<style scoped></style>
